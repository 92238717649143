

.navbar{
  background-color: #333;
  color: white;
  position: absolute !important;
  top: 0;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: .8;
}



.logo {
  display: flex;
  align-items: center;
  width: 65px;
  height: 65px;
}

.logo-div {
  margin-right: auto;
}


.navbar-brand {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 25px !important;

}

.navbar-links {

  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar-links ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navbar-toggler {
  border-color: white;
}

.navbar-links li {
  margin: 0 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
}

.nav-link:hover {
  color: white;
}



/*ALL CODE ABOVE THIS LINE WORKS AS INTENDED */

@media (max-width: 420px) {
  .navbar-brand.logo-text {
    position: fixed;
    font-size: 16px !important;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

}

@media (max-width: 300px) {
  .navbar-brand.logo-text {
    position: fixed;
    font-size: 12px !important;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
  }

  .logo {
    display: flex;
    align-items: center;
    width: 45px;
    height: 45px;
  }

  .navbar-toggler-icon{
    width: 15px;
    height: 25px;

  }

}
