.quote-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px;
    height: auto;
    padding: 5rem;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 35px;
    opacity: 0;
    animation: fadeInOut 10s infinite;
    font-size: 28px;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
     opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media(max-width: 767px){
    .quote-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      max-width: 300px;
      height: auto;
      padding: 2rem;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.8);
      border-radius: 35px;
      opacity: 0;
      animation: fadeInOut 10s infinite;
      font-size: 28px;
    }

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      50% {
       opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

  }
