.about-me-wrapper {
    background-image: url('./alice-table.jpg');
    height: 100vh;
    width: 100vw;

  }

  .about-me-container {
    position: fixed;
    top: 53%;
    left: 50%;
    transform: translate(-50%, -50%) rotateX(90deg);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 60%;
    height: auto;
    padding: 20px;
    opacity: 0.9;
    animation: fadeIn 10s forwards;

  }

  @keyframes fadeIn {
    from {
      transform: translate(-50%, -50%) rotateX(90deg);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%) rotateX(0deg);
      opacity: .9;
    }
  }


  p {
    text-indent: 2em;
  }


  @media screen and (orientation: landscape) and (max-width: 1500px){
    .about-me-container {
      position: fixed;
      font-size: 24px;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%) rotateX(90deg);
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      width: 90%;
      height: auto;
      padding: 20px;
      opacity: 0.9;
      animation: fadeIn 10s forwards;

    }
  }

    @media screen and (orientation: landscape) and (max-width: 1100px){
      .about-me-container {
        position: fixed;
        font-size: 16px;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%) rotateX(90deg);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 90%;
        height: auto;
        padding: 20px;
        opacity: 0.9;
        animation: fadeIn 10s forwards;

      }


    }

    @media screen and (max-width: 920px){
      .about-me-container {
        position: fixed;
        font-size: 26px;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%) rotateX(90deg);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 80%;
        height: auto;
        padding: 20px;
        opacity: 0.9;
        animation: fadeIn 10s forwards;

      }

    }

  @media screen and (max-width: 670px){
    .about-me-container {
      position: fixed;
      font-size: 14px;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%) rotateX(90deg);
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      width: 80%;
      height: auto;
      padding: 20px;
      opacity: 0.9;
      animation: fadeIn 10s forwards;

    }

  }

  @media screen and (max-width: 400px){
    .about-me-container {
      position: fixed;
      font-size: 12px;
      top: 51%;
      left: 50%;
      transform: translate(-50%, -50%) rotateX(90deg);
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      width: 80%;
      height: auto;
      padding: 20px;
      opacity: 0.9;
      animation: fadeIn 10s forwards;

    }
  }

    @media screen and (max-width: 300px){
      .about-me-container {
        position: fixed;
        font-size: 10px;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%) rotateX(90deg);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 80%;
        height: auto;
        padding: 20px;
        opacity: 0.9;
        animation: fadeIn 10s forwards;

      }
    }

    @media screen and (orientation: landscape) and (max-width: 1500px){
      .about-me-container {
        position: fixed;
        font-size: 24px;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%) rotateX(90deg);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 90%;
        height: auto;
        padding: 20px;
        opacity: 0.9;
        animation: fadeIn 10s forwards;

      }

      @media screen and (orientation: landscape) and (max-width: 1100px){
        .about-me-container {
          position: fixed;
          font-size: 16px;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%) rotateX(90deg);
          background-color: white;
          border-radius: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          width: 90%;
          height: auto;
          padding: 20px;
          opacity: 0.9;
          animation: fadeIn 10s forwards;

        }


      }

      @media screen and (max-width: 920px){
        .about-me-container {
          position: fixed;
          font-size: 12px;
          top: 52%;
          left: 50%;
          transform: translate(-50%, -50%) rotateX(90deg);
          background-color: white;
          border-radius: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          width: 80%;
          height: auto;
          padding: 20px;
          opacity: 0.9;
          animation: fadeIn 10s forwards;

        }

      }

    @media screen and (max-width: 670px){
      .about-me-container {
        position: fixed;
        font-size: 14px;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%) rotateX(90deg);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 80%;
        height: auto;
        padding: 20px;
        opacity: 0.9;
        animation: fadeIn 10s forwards;

      }

    }

    @media screen and (max-width: 400px){
      .about-me-container {
        position: fixed;
        font-size: 12px;
        top: 51%;
        left: 50%;
        transform: translate(-50%, -50%) rotateX(90deg);
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        width: 80%;
        height: auto;
        padding: 20px;
        opacity: 0.9;
        animation: fadeIn 10s forwards;

      }
    }

      @media screen and (max-width: 300px){
        .about-me-container {
          position: fixed;
          font-size: 10px;
          top: 51%;
          left: 50%;
          transform: translate(-50%, -50%) rotateX(90deg);
          background-color: white;
          border-radius: 10px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          width: 80%;
          height: auto;
          padding: 20px;
          opacity: 0.9;
          animation: fadeIn 10s forwards;

        }
      }

    }
