.bg{
    background-image: url('./HomeBG-3.jpg');
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    position: relative;
    z-index: -1;

  }

  .photo{
    position: relative;
    top: 50%;
  }

  @media (max-width: 767px) {
    /* styles for screens smaller than 768px */
    

    .photo {
      top: 30%;
    }
  }
