.footer {
    background-color: #333;
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .6;
    
  }

  .container {
    max-width: 1200px;
    width: 100%;
    padding: 0 20px;
    text-align: center;
  }
